.home {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 1500px;
}

.home__container{
  position: relative;

}

.splash{
  position: absolute;
  top: 150px;
  right: 20px;
  width: 150px;
  background: #cc1100;
  padding: 1rem;
  border-radius: 20px;
  color: #ffa414;
  opacity: 0.8;
  z-index: 20;
}

.video__spot {
  position: absolute;
  display: block;
  top: 10px;
  right: 20px;
  width: 680px;
  height: 250px;
}

.splash a {
  font-weight: bolder;
  color: aliceblue;
}

.home__row {
  display: flex;
  z-index: 1;
  margin-left: 5px;
  margin-right: 5px;
}

.home__image {
  width: 100%;
  z-index: -1;
  margin-bottom: -150px;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}
